import React from 'react';
import { graphql } from 'gatsby';
// import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/helpers';
import Leads from '../components/Leads';
// import PipedriveForm from '../components/PipedriveForm';
import Heading from '../components/Heading/Heading';
import Helmet from 'react-helmet';

import './feedback-page.scss';

const FeedbackPage = ({ data, location }) => {
  const { wordpressPost: page, wordpressWpSettings, siteSettings } = data;
  const { wordpressUrl } = wordpressWpSettings;
  if (!page) return null;
  const { title, yoast, content } = page;
  const { title: siteTitle } = wordpressWpSettings;
  
  return (
    <Layout location={location} wordpressUrl={wordpressUrl}>
      <SEO
        title={`${
          yoast.metaTitle ?
          yoast.metaTitle :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section className="feedback-page-section">
        <div className="wrap">
          <Heading tag="h1" className="page-title">{decodeEntities(title)}</Heading>
          <div className="content" dangerouslySetInnerHTML={{__html: content}} />
          <div className="form-container">
            <h2 className="feedback-form-title">Feedback Form</h2>
            {/* <PipedriveForm /> */}
            <Leads id={14} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default FeedbackPage;

export const pageQuery = graphql`
  query FeedbackPage($id: String!) {
    wordpressWpSettings {
      title
      siteUrl
      wordpressUrl
    },
    wordpressPost: wordpressPage(id: { eq: $id }) {
      title
      content
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
    }
  }
`
